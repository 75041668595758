import * as React from 'react';
import _ from 'lodash';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation, MutationFn, Query, withApollo, WithApolloClient } from 'react-apollo';
import * as qs from 'qs';
import Helmet from 'react-helmet';
import * as mockupRoutes from 'inkp-mockup-sdk/routes';

import { routes, url, path } from 'inkp-routes/public';
import * as t from 'inkp-order-sdk/types.g';
import Notif from 'inkp-components/dist/Components/Notif';
import TextArea from 'inkp-components/dist/Components/TextArea';
import Modal from 'inkp-components/dist/Components/Modal';
import Zoomable from 'inkp-components/dist/Components/Zoomable';
import Zoomed from 'inkp-components/dist/Components/Zoomed';
import SearchIconPlus from 'inkp-components/dist/Icons/SearchIconPlus';
import PrimaryButton from 'inkp-components/dist/Components/PrimaryButton';
import WithNextAndBackArrows from 'inkp-components/dist/HOC/WithNextAndBackArrows';
import Loading from 'inkp-components/dist/Components/Loading';
import Alert from 'inkp-components/dist/Components/Alert';

import { StateContainer, Subscribe } from 'infra-frontend/helpers/apollo';
import { PRINT_HORIZONTAL_READ_MAP } from 'oo-print-sdk/design.public';
import { PRINT_HORIZONTAL_ENUM, PRINT_SIDES_ENUM } from 'oo-print-sdk/types/design';
import { parseProductItemId } from 'inkp-product-sdk';
import { isUserRegistered } from 'inkp-user-sdk/user';
import { USER_ROLE } from 'inkp-user-sdk/types.g';
import { CURRENT_USER_QUERY, getCurrentUser } from '../../util/login';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

const ORDER_ARTWORK_APPROVAL_FRAGMENT = gql`
  fragment OrderArtworkApprovalFragment on Order {
    id
    status
    userId
    quote {
      items {
        product {
          productItemId
          product {
            id
            name
            colors {
              name
              hex
            }
            designTemplates {
              side
              id
            }
          }
        }
        design {
          id
          sides {
            name
            artwork {
              position {
                horizontal
                offset {
                  top
                  bottom
                }
              }
              dimensions {
                width
                height
              }
              processedArtworkId
              processedUrl
              processedRaster
              processedAspect
            }
          }
          updatedAt
        }
      }
    }
  }
`

const ORDER_BY_ID_AND_USER_QUERY = gql`
  query orderByIdAndUser($id: String!) {
    orderByIdAndUser(id: $id) {
      ...OrderArtworkApprovalFragment
    }
  }

  ${ORDER_ARTWORK_APPROVAL_FRAGMENT}
`;

const GET_ORDER_BY_ID_AND_EMAIL_QUERY = gql`
  query GetOrderByIdAndEmail($id: String!, $email: String!) {
    orderByIdAndEmail(id: $id, email: $email) {
      ...OrderArtworkApprovalFragment
    }
  }

  ${ORDER_ARTWORK_APPROVAL_FRAGMENT}
`

const SUBMIT_CUSTOMER_APPROVAL = gql`
  mutation submitCustomerApproval($orderId: String!, $designApprovals: [OrderDesignApprovalInput!]!) {
    handleCustomerApproval(orderId: $orderId, designApprovals: $designApprovals) {
      id
      status
    }
  }
`;

const SUBMIT_CUSTOMER_APPROVAL_WITH_EMAIL = gql`
  mutation submitCustomerApprovalWithEmail($orderId: String!, $email: String!, $designApprovals: [OrderDesignApprovalInput!]!) {
    handleCustomerApprovalWithEmail(orderId: $orderId, email: $email, designApprovals: $designApprovals) {
      id
      status
    }
  }
`;

class ArtworkApprovalState extends StateContainer {
  initialState = {
    currentArtworkIndex: 0,
    currentMockupIndex: 0,
    requestChanges: false,
    feedback: '',
    saveError: '',
    inputs: [],
  };

  shape = `
    {
      currentArtworkIndex
      currentMockupIndex
      requestChanges
      feedback
      saveError
      inputs {
        designId
        side
        approved
        feedback
      }
    }
  `;

  onLeftClick = (currentMockupIndex: number, productLength: number) => () => {
    if (currentMockupIndex - 1 < 0) {
      this.setState({ currentMockupIndex: productLength - 1 });
    } else {
      this.setState({ currentMockupIndex: currentMockupIndex - 1 });
    }
  }

  onRightClick = (currentMockupIndex: number, productLength: number) => () => {
    this.setState({ currentMockupIndex: (currentMockupIndex + 1) % productLength });
  }

  onRequestChanges = () => () => {
    this.setState({ requestChanges: true });
  }

  onCancel = () => () => {
    this.setState({ requestChanges: false, feedback: '' });
  }

  onFeedback = () => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ feedback: event.target.value });
  }

  onSubmitApprove = (designId: string, side: string, isLastArtwork: boolean, mutationFn: (inputs: any) => void) => () => {
    const inputs = _.cloneDeep(this.state.inputs);
    inputs.push({ designId, side, approved: true, feedback: '' });
    if (!isLastArtwork) {
      this.setState({ inputs, currentArtworkIndex: inputs.length, currentMockupIndex: 0 });
    } else {
      mutationFn(inputs);
    }
  }

  onSubmitFeedback = (designId: string, side: string, feedback: string, isLastArtwork: boolean, mutationFn: (inputs: any) => void) => () => {
    const inputs = _.cloneDeep(this.state.inputs);
    inputs.push({ designId, side, approved: false, feedback });
    if (!isLastArtwork) {
      this.setState({ inputs, currentArtworkIndex: inputs.length, currentMockupIndex: 0 });
    } else {
      mutationFn(inputs);
    }
  }

  onError = () => () => {
    this.setState({ saveError: 'There was an error saving your artwork approval. Please contact customer support' });
  }

  onErrorClose = () => () => {
    this.setState({ saveError: '' });
  }

}

interface ArtworkApprovalInput {
  designId: string,
  side: string,
  approved: boolean,
  feedback: string,
}

interface ArtworkApprovalProduct {
  productItemId: string;
  templateIdentifier: string;
  colors: {
    hex: string,
    name: string,
  }[];
}

interface ArtworkApprovalArtwork {
  artworkRaster: string;
  designId: string;
  designUpdatedAt: string;
  side: PRINT_SIDES_ENUM;
  aspect: number;
  position: {
    offset: {
      top?: string,
      bottom?: string,
    },
    horizontal: PRINT_HORIZONTAL_ENUM,
  };
  dimensions: {
    height?: number,
    width?: number,
  };
  products: ArtworkApprovalProduct[];
}

class ArtworkApproval extends React.Component<WithApolloClient<RouteComponentProps>> {

  state = {
    zoomArtwork: {
      zoom: false,
      box: {
        size: {
          height: undefined
        }
      },
    },
    zoomMockup: {
      zoom: false,
      box: {
        size: {
          height: undefined
        }
      },
    },
  };

  private renderOrderNotFound(orderId: string) {
    return (
      <div className="flex flex-col w-full px-1 md:w-container md:mx-auto">
        <Alert
          alerts={[
            {
              type: 'error',
              title: 'Failed to lookup order',
              content: `Could not find order ${orderId}`,
            },
          ]}
        />
      </div>
    )
  }

  getArtworks = (order: any) => {
    if (!order) return [];
    if (order.status !== t.ORDER_STATUS_ENUM.PENDING_CUSTOMER_APPROVAL) return [];
    const artworksByDesignSide: {[key: string]: ArtworkApprovalArtwork} = {};
    const productDesignItems = _.uniqBy(order.quote.items, (item: any) => {
      const { productId, color } = parseProductItemId(item.product.productItemId);
      return `${item.design.id}-${productId}-${color}`;
    });
    for (const item of productDesignItems) {
      for (const side of item.design.sides) {
        if (!side.artwork.processedRaster) continue;
        const key = this.getDesignSideKey(item.design.id, side.name);
        const existingArtwork = artworksByDesignSide[key];
        const matchingDesignTemplate = _.find(item.product.product.designTemplates, (designTemplate) => { return designTemplate.side === side.name.toUpperCase(); });
        const templateIdentifier = (matchingDesignTemplate || {}).id;
        const colors = _.map(item.product.product.colors, (color) => { return { name: color.name, hex: color.hex };});
        if (existingArtwork) {
          existingArtwork.products.push({ productItemId: item.product.productItemId, templateIdentifier, colors });
        } else {
          artworksByDesignSide[key] = {
            artworkRaster: side.artwork.processedRaster,
            designId: item.design.id,
            designUpdatedAt: item.design.updatedAt.toString(),
            side: side.name,
            aspect: side.artwork.processedAspect,
            position: side.artwork.position,
            dimensions: side.artwork.dimensions,
            products: [{ productItemId: item.product.productItemId, templateIdentifier, colors }],
          };
        }
      }
    }
    return _.values(artworksByDesignSide);
  }

  getDesignSideKey = (designId: string, side: string) => {
    return `${designId}-${side}`;
  }

  getMockupUrl = (artwork: ArtworkApprovalArtwork, product: ArtworkApprovalProduct) => {
    const { designId, designUpdatedAt, side } = artwork;
    const { templateIdentifier, productItemId } = product;
    const { color } = parseProductItemId(productItemId);
    const colorHex = (_.find(product.colors, (_color) => { return _color.name.toLowerCase() === color; }) || { hex: '000000' }).hex;
    const size = 'regular';
    return url('mockup', mockupRoutes.default.mockupRerender, { designId, templateIdentifier, side, color: colorHex, size, designUpdatedAt });
  }

  collateArtworkApproval = (inputs: ArtworkApprovalInput[]) => {
    const designApprovals: t.OrderDesignApprovalInput[] = [];
    const inputsByDesignId = _.groupBy(inputs, (input) => { return input.designId });
    for (const designId of Object.keys(inputsByDesignId) ) {
      const designInputs = inputsByDesignId[designId];
      if (_.every(designInputs, (designInput) => { return designInput.approved })) {
        designApprovals.push({
          designId: designId,
          approved: true,
        });
      } else {
        let designApprovalFeedback = '';
        for (const input of inputsByDesignId[designId]) {
          if (input.feedback) {
            designApprovalFeedback = designApprovalFeedback.concat(`${input.side}: ${input.feedback}\n`);
          }
        }
        designApprovals.push({
          designId: designId,
          approved: false,
          message: designApprovalFeedback,
        })
      }
    }
    return designApprovals
  }

  submitCustomerApproval = (submitCustomerApprovalMu: MutationFn, orderId: string) => (inputs: ArtworkApprovalInput[]) => {
    const designApprovals: t.OrderDesignApprovalInput[] = this.collateArtworkApproval(inputs);
    submitCustomerApprovalMu({
      variables: {
        orderId,
        designApprovals,
      },
    });
  }

  submitCustomerApprovalWithEmail = (submitCustomerApprovalWithEmailMu: MutationFn, orderId: string, email: string) => (inputs: ArtworkApprovalInput[]) => {
    const designApprovals: t.OrderDesignApprovalInput[] = this.collateArtworkApproval(inputs);
    submitCustomerApprovalWithEmailMu({
      variables: {
        orderId,
        email,
        designApprovals,
      },
    });
  }

  renderArtwork = ({
    currentArtworkIndex,
    currentMockupIndex,
    artworks,
    onLeftClick,
    onRightClick,
  }: {
    currentArtworkIndex: number,
    currentMockupIndex: number,
    artworks: ArtworkApprovalArtwork[],
    onLeftClick: (currentMockupIndex: number, productLength: number) => () => void,
    onRightClick: (currentMockupIndex: number, productLength: number) => () => void,
  }) => {

    if (artworks.length < 1) return;

    const artwork = artworks[currentArtworkIndex];
    const width = artwork.dimensions.height ? _.round(artwork.dimensions.height * artwork.aspect, 2) : _.round(artwork.dimensions.width || 0, 2);
    const height = artwork.dimensions.width ? _.round(artwork.dimensions.width / artwork.aspect, 2) : _.round(artwork.dimensions.height || 0, 2);

    return (
      <React.Fragment>
        <div className="w-full ta-left">
          <h2 className="fw-bold color-navy mb-1 fs-lg">Artwork {currentArtworkIndex + 1} of {artworks.length}</h2>
        </div>

        <div className="bwr-1 bwt-1 bwl-1 brt bc-gray-200">
          <div className="flex flex-col lg:flex-row justify-center ta-center">
            <style jsx={true}>{`
              .artwork-approval-image-container {
                height: 100%;
              }

              @media only screen and (min-width: 1024px) {
                .artwork-approval-image-container {
                  height: 368px;
                }
              }
            `}</style>
            {/* Left side artwork */}
            <div className="p-relative w-full lg:w-1/2 lg:bwr-1 bwb-1 bc-gray-200 overflow-hidden brtl" id="mockupZoomBox" style={{ zIndex: 1 }}>
              <Zoomed
                className="bgc-white"
                // @ts-ignore
                zoom={this.state.zoomMockup}
              />
              <h3 className="fw-bold fs-md ta-center pt-1 lg:pt-1p5 pb-1">Artwork</h3>
              <div className="flex m-auto justify-center artwork-approval-image-container">
                <div className="self-center">
                  {/* desktop only */}
                  <div className="d-n lg:d-b mx-1 lg:mx-2" style={{ maxHeight: '368px' }}>
                    <div className="p-relative bc-navy-200 bw-1 px-1 pt-1 pb-2p5">
                      <Zoomable
                        onZoom={(zoom) => { this.setState({ zoomArtwork: zoom }) }}
                        // @ts-ignore
                        zoom={this.state.zoomArtwork}
                        // @ts-ignore
                        zoomBox={() => document.querySelector('#artworkZoomBox')}
                      >
                        <img
                          className="d-b"
                          src={artwork.artworkRaster}
                          style={{ objectFit: 'contain', maxHeight: '310px' }}
                        />
                      </Zoomable>
                      <div className="p-absolute pin-r pr-1 pin-b pb-p25">
                        <SearchIconPlus width="20px" height="20px" fill="#B3B0B7"/>
                      </div>
                    </div>
                  </div>
                  {/* mobile only */}
                  <div className="d-b lg:d-n px-1">
                    <img
                      className="d-b px-1 py-1p5 bc-navy-200 bw-1"
                      src={artwork.artworkRaster}
                      style={{ objectFit: 'contain' }}
                    />
                  </div>
                </div>
              </div>
              <h3 className="fw-bold fs-md ta-center pt-p5 lg:pt-1">Artwork Dimension</h3>
              <div className="pt-p25">W: { width }"</div>
              <div className="pb-1 lg:pb-1p5">H: { height }"</div>
            </div>


            {/* Right side product mockups */}
            <div className="p-relative w-full lg:w-1/2 w-1/2 bwb-1 bc-gray-200 ta-center overflow-hidden brtr" id="artworkZoomBox" style={{ zIndex: 1 }}>
              <Zoomed
                className="bgc-white"
                // @ts-ignore
                zoom={this.state.zoomArtwork}
              />
              <h3 className="fw-bold fs-md ta-center pt-1 pb-p75 lg:pt-1p5 lg:pb-1">Product Mockup {currentMockupIndex + 1} of {artwork.products.length}</h3>
              <div className="flex">
                <div className="p-relative flex items-center justify-center w-full h-full">
                  { artwork.products.length > 1 &&
                    <div className="p-absolute w-full px-1 lg:px-4p5">
                      <WithNextAndBackArrows
                        size="sm"
                        onLeftClick={onLeftClick(currentMockupIndex, artwork.products.length)}
                        onRightClick={onRightClick(currentMockupIndex, artwork.products.length)}
                      />
                    </div>
                  }
                  {/* desktop only */}
                  <div className="d-n lg:d-b px-0">
                    <Zoomable
                      onZoom={(zoom) => { this.setState({ zoomMockup: zoom }) }}
                      // @ts-ignore
                      zoom={this.state.zoomMockup}
                      // @ts-ignore
                      zoomBox={() => document.querySelector('#mockupZoomBox')}
                    >
                      <img
                        className="br d-b"
                        alt="Loading..."
                        src={this.getMockupUrl(artwork, artwork.products[currentMockupIndex])}
                        style={{ objectFit: 'contain', height: 368, width: 298 }}
                      />
                      <div className="p-absolute pin-r pr-p75 pin-b pb-p25">
                        <SearchIconPlus width="20px" height="20px" fill="#B3B0B7"/>
                      </div>
                    </Zoomable>
                  </div>
                  {/* mobile only */}
                  <div className="d-b lg:d-n px-1">
                    <img
                      className="br d-b"
                      src={this.getMockupUrl(artwork, artwork.products[currentMockupIndex])}
                      style={{ objectFit: 'contain' }}
                    />
                  </div>
                </div>
              </div>
              <h3 className="fw-bold fs-md ta-center pt-p5 lg:pt-1">Placement</h3>
              <div className="pt-p25">{artwork.position.offset.top || artwork.position.offset.bottom} inches from {_.keys(artwork.position.offset)[0]}</div>
              <div className="pb-1 lg:pb-1p5">{PRINT_HORIZONTAL_READ_MAP[artwork.position.horizontal as PRINT_HORIZONTAL_ENUM]}</div>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }

  renderActions = ({
    requestChanges,
    currentArtworkIndex,
    artworks,
    feedback,
    onSubmitCustomerApproval,
    onSubmitApprove,
    onSubmitFeedback,
    onRequestChanges,
    onFeedback,
    onCancel,
  }: {
    requestChanges: boolean,
    currentArtworkIndex: number,
    artworks: ArtworkApprovalArtwork[],
    feedback: string,
    onSubmitCustomerApproval: (inputs: any) => void,
    onSubmitApprove: (designId: string, side: string, isLastArtwork: boolean, onSubmitCustomerApproval: any) => () => void,
    onSubmitFeedback: (designId: string, side: string, feedback: string, isLastArtwork: boolean, onSubmitCustomerApproval: any) => () => void,
    onRequestChanges: () => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    onFeedback: () => (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    onCancel: () => (event: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  }) => {

    if (artworks.length < 1) return;

    const artwork = artworks[currentArtworkIndex];
    const isLastArtwork = currentArtworkIndex === artworks.length - 1;

    if (!requestChanges) {
      return (
        <div className="flex items-center flex-row-reverse brb bwr-1 bwl-1 bwb-1 bc-gray-200 bgc-gray-50 lg:fs-md">
          <div className="px-1 lg:px-1p5 py-1">
            <PrimaryButton
              size="lg"
              onClick={onSubmitApprove(artwork.designId, artwork.side, isLastArtwork, onSubmitCustomerApproval)}
            >
              Approve & { isLastArtwork ? 'Done' : 'Next' }
            </PrimaryButton>
          </div>
          <a onClick={onRequestChanges()} className="color-gray-500 mx-p5">Request Changes</a>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="bwr-1 bwl-1 bc-gray-200 py-1 lg:py-1p5 px-1 lg:px-1p5">
          <h3 className="fw-bold fs-md">Request Changes</h3>
          <div className="flex pt-1 lg:pt-1p25">
            <TextArea
              label="Feedback"
              resize={true}
              autoFocus={true}
              value={feedback}
              placeholder="Let us know what needs to be changed"
              onChange={onFeedback()}
            />
          </div>
        </div>
        <div className="flex items-center flex-row-reverse brb bw-1 bc-gray-200 bgc-gray-50">
          <div className="px-1 lg:px-1p5 py-1">
            <PrimaryButton
              size="lg"
              disabled={!feedback}
              onClick={onSubmitFeedback(artwork.designId, artwork.side, feedback, isLastArtwork, onSubmitCustomerApproval)}
            >
              Submit Feedback & { isLastArtwork ? 'Done' : 'Next' }
            </PrimaryButton>
          </div>
          <a onClick={onCancel()} className="color-navy-500 mx-p5 lg:mr-p5">Cancel</a>
        </div>
      </React.Fragment>
    )
  }

  onCompleted = () => (data: any) => {
    const { orderId, email } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const status: t.ORDER_STATUS_ENUM.PROCESSING | t.ORDER_STATUS_ENUM.PRODUCTION =
      _.get(data, 'handleCustomerApprovalWithEmail.status') || _.get(data, 'handleCustomerApproval.status');
    const message = status === t.ORDER_STATUS_ENUM.PROCESSING
      ? "Artwork feedback submitted successfully. We will contact you as soon as it's ready for a review."
      : "Artwork approval submitted successfully. Your order is now printing.";
    this.props.history.push({
      pathname: path(routes.app.order.getById, { orderId }),
      search: email ? `?email=${encodeURIComponent(email)}` : '',
      state: { message },
    });
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    const { orderId, email } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    const user = getCurrentUser(this.props.client.cache);
    const _isUserRegistered = isUserRegistered(user);

    return (
      <Subscribe to={[ArtworkApprovalState]} namespace="ArtworkApproval">
        {(page) => {
          const { state, onLeftClick, onRightClick, onRequestChanges, onFeedback, onCancel, onSubmitApprove, onSubmitFeedback, onError, onErrorClose } = page;
          const { currentArtworkIndex, currentMockupIndex, requestChanges, feedback, saveError } = state;
          return (
            <Query query={GET_ORDER_BY_ID_AND_EMAIL_QUERY} variables={{ id: orderId, email}} skip={_isUserRegistered}>
              {({ loading: emailOrderLoading, error: emailOrderError, data: emailOrderData }: any) => (
                <Query query={ORDER_BY_ID_AND_USER_QUERY} variables={{ id: orderId }} skip={!_isUserRegistered} fetchPolicy="network-only">
                  {({
                    loading,
                    error,
                    data
                  }: {
                    loading: boolean,
                    error?: any,
                    data: any
                  }) => {
                    const order = _.get(data, 'orderByIdAndUser') || _.get(emailOrderData, 'orderByIdAndEmail');
                    const artworks = this.getArtworks(order);

                    if (emailOrderLoading || loading) {
                      return <Loading />
                    }
                    if (!orderId || !order) return this.renderOrderNotFound(orderId);

                    return (
                      <Mutation mutation={SUBMIT_CUSTOMER_APPROVAL_WITH_EMAIL} onError={onError()} onCompleted={this.onCompleted()}>
                        {(submitCustomerApprovalWithEmail: MutationFn) => (
                          <Mutation mutation={SUBMIT_CUSTOMER_APPROVAL} onError={onError()} onCompleted={this.onCompleted()}>
                            {(submitCustomerApproval: MutationFn) => {
                              const emailApprovalMutation = this.submitCustomerApprovalWithEmail(submitCustomerApprovalWithEmail, orderId, email);
                              const registeredApprovalMutation = this.submitCustomerApproval(submitCustomerApproval, orderId);
                              const onSubmitCustomerApproval = _isUserRegistered ? registeredApprovalMutation : emailApprovalMutation;

                              return (
                                <React.Fragment>
                                  <Helmet>
                                    <title>Inkpop - Artwork Approval</title>
                                  </Helmet>

                                  <div className="flex flex-col w-full px-1 lg:px-0 lg:w-container lg:mx-auto mb-7 lg:mb-4p5">
                                    <div className="w-full py-1p5">
                                      <Link to={path(routes.app.order.getById, { orderId, email })} className="color-blue fs-md fw-bold">
                                        <i className="d-ib mdi mdi-chevron-left color-blue fs-xl" />
                                        <h3 className="d-ib fs-md align-text-bottom">Back</h3>
                                      </Link>
                                    </div>
                                    <div className="w-full pb-2">
                                      <h1 className="fw-extra-bold color-navy fs-xl">Approve Your Artwork</h1>
                                    </div>

                                    { emailOrderError || error &&
                                      <Notif type="error">There was an error fetching your order. Please try again later</Notif>
                                    }

                                    { saveError &&
                                      <Modal>
                                        <Notif type="error" onClose={onErrorClose()} >{saveError}</Notif>
                                      </Modal>
                                    }

                                    { order.id && order.status !== t.ORDER_STATUS_ENUM.PENDING_CUSTOMER_APPROVAL &&
                                      <Notif type="warning">This order does not require artwork approval.</Notif>
                                    }

                                    { this.renderArtwork({
                                        currentArtworkIndex,
                                        currentMockupIndex,
                                        artworks,
                                        onLeftClick,
                                        onRightClick,
                                      })
                                    }

                                    { this.renderActions({
                                        requestChanges,
                                        currentArtworkIndex,
                                        artworks,
                                        feedback,
                                        onSubmitCustomerApproval,
                                        onSubmitApprove,
                                        onSubmitFeedback,
                                        onRequestChanges,
                                        onFeedback,
                                        onCancel,
                                      })
                                    }

                                  </div>
                                </React.Fragment>
                              )
                            }}
                          </Mutation>
                        )}
                      </Mutation>
                    );
                  }
                }
              </Query>
            )}
          </Query>
        )}}
      </Subscribe>
    );
  }
}

export default withApollo(ArtworkApproval);
